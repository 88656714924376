<template>
	<base-link to="realizations" :query="{category: card.link_alias}" class="card" :title="card.link_title" :class="{'plain-card': disableHover}" v-animate="`opacity`">
		<cms-image
				class="card-img"
				lazy
				:src="card.image"
				:base="{width: 318, height: 367}"
				:lg="{width: 690, height: 553}"
			/>
		<cms-text
			class="card-hover"
			:value="`${prefix}.hover_text`"
			:props="{variant: 'small-heading', color: 'white', weight: 'semiBold'}"
		/>
		<cms-text
			class="card-title"
			:value="`${prefix}.card_schema.${card.id}.card_title`"
			:props="{variant: 'small-heading', marginReset: true, color: 'white'}"
		/>
	</base-link>
</template>
<script>
export default {
	props: {
		card: Object,
		prefix: String,
		disableHover: {
			type: Boolean,
			default: false
		}
	}
}
</script>
<style lang="scss" scoped>
.card {
	width: 100%;
	height: 100%;
	position: relative;
	::v-deep .base-image {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
	::v-deep &-title {
		position: absolute;
		bottom: 2rem;
		left: 2rem;
		z-index: 2;
		white-space: nowrap
	}
	&-hover {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-90%);
		z-index: 3;
		opacity: 0;
		transition: .3s ease-in;
	}
	@include media-breakpoint-up(xl) {
		&:hover {
			.card-hover {
				transform: translate(-50%,-50%);
				opacity: 1;
			}
			&::after {
				height: 100%;
			}
		}
	}
	&::after, &::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}
	&::before {
		height: 100%;
		background: rgba($black, .5);
	}
	&::after {
		background: rgba($secondary, .83);
		height: 6px;
		transition: .3s ease-in;
		@include media-breakpoint-up(xl) {
			height: 9px;
		}
	}

}
</style>
